import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import theme from "./theme";
import { AppProvider } from "./utils/context/AppProvider";
import { ThemeProvider } from "@mui/material";
import "./translations";
import { NotificationProvider } from "./utils/context/NotificationProvider";
import { TEST_MODE_IS_ON } from "./config/constants";

const {
  SENTRY_DSN,
  SENTRY_TRACES_RATE,
  SENTRY_ENVIRONMENT,
  IMAGE_VERSION,
  BUILD_VERSION,
} = window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

async function enableMocking() {
  if (!TEST_MODE_IS_ON) return;
  const { worker } = await import("./api/mocks/browser.js");
  return worker.start();
}

console.log(IMAGE_VERSION, BUILD_VERSION);

enableMocking().then(() => {
  root.render(
    <NotificationProvider>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AppProvider>
    </NotificationProvider>
  );
});
