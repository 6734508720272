import heic2any from "heic2any";
import { roiColor } from "./constants";
import { Roi, bbox } from "./types";
import {
  face_fd_roi_padding,
  face_fd_roi_ratio,
  image_area_threshold_mp,
} from "../config/constants";

export const bboxIsInsideRoi = (
  bbox: bbox,
  ROI: [number, number, number, number]
) => {
  return (
    bbox.x >= ROI[0] &&
    bbox.y >= ROI[1] &&
    bbox.x + bbox.width <= ROI[0] + ROI[2] &&
    bbox.y + bbox.height <= ROI[1] + ROI[3]
  );
};

export const checkMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
};

export const calculateRoiPosition = (
  videoWidth: number,
  videoHeight: number,
  shrinkCoef: number = 0
) => {
  const w =
    videoWidth /
    ((1 + 2 * (face_fd_roi_padding + shrinkCoef)) * face_fd_roi_ratio);
  const h = videoHeight / (1 + 2 * (face_fd_roi_padding + shrinkCoef));
  const height = Math.min(w, h);
  const width = height * face_fd_roi_ratio;
  const x = videoWidth / 2 - width / 2;
  const y = videoHeight / 2 - height / 2;
  return [x, y, width, height] as Roi;
};

export const downsizeImg = (imgWidth: number, imgHeight: number) => {
  const threshold = image_area_threshold_mp * Math.pow(10, 6);
  const imgArea = imgWidth * imgHeight;
  const downSizeCoef = threshold < imgArea ? Math.sqrt(threshold / imgArea) : 1;
  return {
    downSizedWidth: imgWidth * downSizeCoef,
    downSizedHeight: imgHeight * downSizeCoef,
    initialWidth: imgWidth,
    initialHeight: imgHeight,
  };
};

export const drawROI = (
  canvas: HTMLCanvasElement,
  videoWidth: number,
  videoHeight: number,
  bbox: Roi
) => {
  canvas.width = videoWidth;
  canvas.height = videoHeight;
  const ctx = canvas.getContext("2d")!;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.strokeStyle = roiColor;
  ctx.lineWidth = 10;
  ctx.strokeRect(...bbox);
};

export const drawOnCanvas = (
  canvas: HTMLCanvasElement,
  imgSrc: string,
  mirrored: boolean
) => {
  const ctx = canvas.getContext("2d")!;
  const img = new Image();
  if (mirrored) {
    ctx.scale(-1, 1);
  }
  const width = mirrored ? canvas.width * -1 : canvas.width;
  img.onload = () => {
    ctx.drawImage(img, 0, 0, width, canvas.height);
  };
  img.src = imgSrc;
};

export const clearCanvas = (canvas: HTMLCanvasElement, mirrored?: boolean) => {
  const ctx = canvas.getContext("2d")!;
  const width = mirrored ? canvas.width * -1 : canvas.width;
  ctx.clearRect(0, 0, width, canvas.height);
};

export const heicToURL = async (file: File) => {
  // get image as blob url
  let blobURL = URL.createObjectURL(file);
  // convert "fetch" the new blob url
  let blobRes = await fetch(blobURL);
  // convert response to blob
  let blob = await blobRes.blob();
  // convert to PNG - response is blob
  let conversionResult = await heic2any({ blob });
  return URL.createObjectURL(conversionResult as Blob);
};

export const adjustHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};

export type Lang = "ru" | "en";

export function isLang(lang: string | null): lang is Lang {
  return lang === "ru" || lang === "en";
}

export const getImageSize: (
  url: string
) => Promise<{ width: number; height: number }> = (url) => {
  return new Promise((res, rej) => {
    const image = new Image();
    image.onload = () => {
      res({ width: image.width, height: image.height });
    };
    image.onerror = () => {
      rej("Error while getting image size");
    };
    image.src = url;
  });
};

export const convertToJpeg = async (url: string) => {
  const res = await new Promise((res, rej) => {
    const image = new Image();
    image.onload = async () => {
      const { width, height } = image;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;
      canvas.width = width;
      canvas.height = height;
      const { downSizedWidth, downSizedHeight } = downsizeImg(width, height);
      if (downSizedWidth < width) {
        canvas.width = downSizedWidth;
        canvas.height = downSizedHeight;
      }
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const blob = await new Promise((res, rej) => {
        canvas.toBlob((blob) => {
          res(blob);
        }, "image/jpeg");
      });
      const url = canvas.toDataURL("image/jpeg");
      res({ blob, url });
    };
    image.onerror = (e: any) => {
      rej(e);
    };
    image.src = url;
  });
  return res as Promise<{ blob: Blob; url: string }>;
};
