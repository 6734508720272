export const makeSnapshot = async (
  video: HTMLVideoElement,
  mirrored?: boolean
) => {
  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext("2d")!;
  const mirror = mirrored ? -1 : 1; // -1 -> mirror
  ctx.scale(mirror, 1);
  ctx.drawImage(video, 0, 0, canvas.width * mirror, canvas.height);
  const blob = await new Promise((res) => {
    canvas.toBlob((blob) => {
      res(blob);
    }, "image/jpeg");
  });
  const url = canvas.toDataURL("image/jpeg");
  return { blob, url };
};
