import {
  FC,
  HTMLProps,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./ResultScreen.module.scss";
import classNames from "classnames";
import { useAppContext } from "../../utils/context/AppProvider";
import { roiColor } from "../../utils/constants";
import { Alert, Button } from "@mui/material";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const ResultScreen: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { t } = useTranslation();

  const {
    firstFaceResult,
    firstFaceImgUrl,
    secondFaceResult,
    secondFaceImgUrl,
    firstPhotoHasMultipleFaces,
    secondPhotoHasMultipleFaces,
    setFirstFaceImgUrl,
    setSecondFaceImgUrl,
    setFirstFaceResult,
    setSecondFaceResult,
    setShowResults,
  } = useAppContext();

  const [firstImgSize, setFirstImgSize] = useState({ w: 0, h: 0 });
  const [secondImgSize, setSecondImgSize] = useState({ w: 0, h: 0 });
  const [firstStrokeWidth, setFirstStrokeWidth] = useState(0);
  const [secondStrokeWidth, setSecondStrokeWidth] = useState(0);
  const firstImgClass = classNames([styles["result-img"], "first-img"]);
  const secondImgClass = classNames([styles["result-img"], "second-img"]);
  const firstAlertClass = classNames([
    styles["alert"],
    { [styles["alert--hidden"]]: !firstPhotoHasMultipleFaces },
  ]);
  const secondAlertClass = classNames([
    styles["alert"],
    { [styles["alert--hidden"]]: !secondPhotoHasMultipleFaces },
  ]);

  const root = useRef(null);
  const resetApp = () => {
    setFirstFaceImgUrl("");
    setSecondFaceImgUrl("");
    setFirstFaceResult(undefined);
    setSecondFaceResult(undefined);
    setShowResults(false);
  };

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.from(".first-img", { opacity: 0 });
    tl.from(".second-img", { opacity: 0 }, "<+=0.3");
    tl.from(`.${styles["button"]}`, { opacity: 0 });
  }, []);

  useEffect(() => {
    const firstImage = new Image();
    const secondImage = new Image();
    firstImage.onload = () => {
      setFirstImgSize({ w: firstImage.width, h: firstImage.height });
      setFirstStrokeWidth((firstImage.width * 3) / 300);
    };
    secondImage.onload = () => {
      setSecondImgSize({ w: secondImage.width, h: secondImage.height });
      setSecondStrokeWidth((secondImage.width * 3) / 300);
    };
    firstImage.src = firstFaceImgUrl;
    secondImage.src = secondFaceImgUrl;
  }, []);

  return (
    <div className={className} ref={root} {...props}>
      <Alert severity="warning" className={firstAlertClass}>
        {t("messages.firstPhotoMultiple")}
      </Alert>
      <Alert severity="warning" className={secondAlertClass}>
        {t("messages.secondPhotoMultiple")}
      </Alert>
      <svg
        className={firstImgClass}
        viewBox={`0 0 ${firstImgSize.w} ${firstImgSize.h}`}
      >
        <image href={firstFaceImgUrl} />
        <rect
          x={firstFaceResult?.bbox.x}
          y={firstFaceResult?.bbox.y}
          width={firstFaceResult?.bbox.width}
          height={firstFaceResult?.bbox.height}
          stroke={roiColor}
          strokeWidth={firstStrokeWidth}
          fillOpacity="0"
        />
      </svg>
      <svg
        className={secondImgClass}
        viewBox={`0 0 ${secondImgSize.w} ${secondImgSize.h}`}
      >
        <image href={secondFaceImgUrl} />
        <rect
          x={secondFaceResult?.bbox.x}
          y={secondFaceResult?.bbox.y}
          width={secondFaceResult?.bbox.width}
          height={secondFaceResult?.bbox.height}
          stroke={roiColor}
          strokeWidth={secondStrokeWidth}
          fillOpacity="0"
        />
      </svg>
      <Button onClick={resetApp} className={styles["button"]}>
        {t("tryAgain")}
      </Button>
    </div>
  );
};

export default ResultScreen;
