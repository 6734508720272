import React, { createContext, useState } from "react";
import { DetectedFace } from "../types";

type Dispatch<T> = React.Dispatch<React.SetStateAction<T>>;

type AppContextType = {
  firstFaceResult: DetectedFace | undefined;
  setFirstFaceResult: Dispatch<DetectedFace | undefined>;
  firstFaceImgUrl: string;
  setFirstFaceImgUrl: Dispatch<string>;
  secondFaceResult: DetectedFace | undefined;
  setSecondFaceResult: Dispatch<DetectedFace | undefined>;
  secondFaceImgUrl: string;
  setSecondFaceImgUrl: Dispatch<string>;
  showResults: boolean;
  setShowResults: Dispatch<boolean>;
  photosCompareScore: number;
  setPhotosCompareScore: Dispatch<number>;
  firstPhotoHasMultipleFaces: boolean;
  setFirstPhotoHasMultipleFaces: Dispatch<boolean>;
  secondPhotoHasMultipleFaces: boolean;
  setSecondPhotoHasMultipleFaces: Dispatch<boolean>;
};

type ProviderType = {
  children: React.ReactNode;
};

const AppContext = createContext({} as AppContextType);

const AppProvider = ({ children }: ProviderType) => {
  const [firstFaceResult, setFirstFaceResult] = useState<DetectedFace>();
  const [firstFaceImgUrl, setFirstFaceImgUrl] = useState("");
  const [secondFaceResult, setSecondFaceResult] = useState<DetectedFace>();
  const [secondFaceImgUrl, setSecondFaceImgUrl] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [photosCompareScore, setPhotosCompareScore] = useState(0);
  const [firstPhotoHasMultipleFaces, setFirstPhotoHasMultipleFaces] =
    useState(false);
  const [secondPhotoHasMultipleFaces, setSecondPhotoHasMultipleFaces] =
    useState(false);

  return (
    <AppContext.Provider
      value={{
        firstFaceResult,
        setFirstFaceResult,
        firstFaceImgUrl,
        setFirstFaceImgUrl,
        secondFaceResult,
        setSecondFaceResult,
        secondFaceImgUrl,
        setSecondFaceImgUrl,
        showResults,
        setShowResults,
        photosCompareScore,
        setPhotosCompareScore,
        firstPhotoHasMultipleFaces,
        setFirstPhotoHasMultipleFaces,
        secondPhotoHasMultipleFaces,
        setSecondPhotoHasMultipleFaces,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("App context is undefined");
  }
  return context;
};

export { AppProvider, useAppContext };
