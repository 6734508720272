import axios from "axios";
import { DetectResponse } from "../utils/types";
import { token } from "../config/constants";

export const compareRequest = async (
  image1: Blob,
  image2: Blob,
  bbox1: number[],
  bbox2: number[]
) => {
  try {
    const formData = new FormData();
    formData.append("image1", image1);
    formData.append("image2", image2);
    const res = await axios<{ data: { score: number } }>({
      url: `/match?face1=${bbox1.join(",")}&face2=${bbox2.join(",")}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    });
    return res.data.data.score;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

const detectRequest = async ({
  blob,
  fd_min_size,
  liveness,
}: {
  blob: Blob;
  fd_min_size: number;
  liveness: boolean;
}) =>
  axios<DetectResponse>({
    url: "/detect",
    method: "POST",
    params: {
      fd_min_size,
      liveness,
    },
    headers: {
      "Content-Type": "image/jpeg",
      Authorization: `Bearer ${token}`,
    },
    data: blob,
  });

export const detectDocumentRequest = async (
  blob: Blob,
  fd_min_size: number
) => {
  return detectRequest({ blob, fd_min_size, liveness: false });
};

export const detectFaceRequest = async (blob: Blob, fd_min_size: number) => {
  return detectRequest({ blob, fd_min_size, liveness: true });
};
