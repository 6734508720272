import { ChangeEvent, FC, HTMLProps, useEffect, useRef, useState } from "react";
import styles from "./InitialScreen.module.scss";
import classNames from "classnames";
import UserVideo from "../../components/UserVideo";
import useUserVideo from "../../components/UserVideo/useUserVideo";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CameraIcon from "@mui/icons-material/Camera";
import {
  bboxIsInsideRoi,
  calculateRoiPosition,
  checkMobile,
  clearCanvas,
  convertToJpeg,
  downsizeImg,
  drawOnCanvas,
  drawROI,
  getImageSize,
} from "../../utils/functions";

import { Roi } from "../../utils/types";
import { makeSnapshot } from "../../components/UserVideo/functions";
import { Button } from "@mui/material";
import { useAppContext } from "../../utils/context/AppProvider";
import useNotification from "../../components/Notification/useNotification";
import useFile from "../../hooks/useFile";
import UploadButton from "../../components/UploadButton";
import Spinner from "../../components/Spinner";
import DocImgPreview from "../../components/DocImgPreview";
import {
  compareRequest,
  detectDocumentRequest,
  detectFaceRequest,
} from "../../api";
import {
  face_fd_min_h_roi_frac,
  doc_fd_min_h_frac,
  liveness_threshold,
  similarity_threshold,
  face_fd_roi_visualization_shrink_frac,
} from "../../config/constants";
import { constraints } from "../../utils/constants";

type FacingMode = "user" | "environment";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const InitialScreen: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { handleFileSelection_file } = useFile();
  const { notifyError, notifyInfo, notifySuccess } = useNotification();
  const roiCanvasRef = useRef<HTMLCanvasElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [roi, setRoi] = useState<Roi>([0, 0, 0, 0]);
  const [cameraFacing, setCameraFacing] = useState<FacingMode>("user");
  const [requestLoader, setRequestLoader] = useState(false);
  const [showDocPreview, setShowDocPreview] = useState(false);
  const [switchCamLoader, setSwitchCamLoader] = useState(true);

  const videoClass = classNames([styles["video"]], {
    [styles["hide-video"]]: switchCamLoader,
  });

  const docPreviewRef = useRef<HTMLImageElement>(null);

  const {
    videoRef,
    size: videoSize,
    cameraList,
    startCam,
    switchCam,
  } = useUserVideo();
  const {
    setFirstFaceResult,
    setFirstFaceImgUrl,
    firstFaceResult,
    secondFaceResult,
    firstFaceImgUrl,
    secondFaceImgUrl,
    setSecondFaceResult,
    setSecondFaceImgUrl,
    setShowResults,
    setPhotosCompareScore,
    setFirstPhotoHasMultipleFaces,
    setSecondPhotoHasMultipleFaces,
  } = useAppContext();

  const handleCapture = async () => {
    if (!videoRef.current) throw new Error("VideoIsNotAvailable");
    const { blob, url } = await makeSnapshot(videoRef.current);
    if (previewCanvasRef.current) {
      const previewCanvas = previewCanvasRef.current;
      const video = videoRef.current!;
      const { downSizedWidth, downSizedHeight } = downsizeImg(
        video.videoWidth,
        video.videoHeight
      );
      if (downSizedWidth < video.videoWidth) {
        previewCanvas.width = downSizedWidth;
        previewCanvas.height = downSizedHeight;
      } else {
        previewCanvas.width = video.videoWidth;
        previewCanvas.height = video.videoHeight;
      }
      drawOnCanvas(previewCanvas, url, cameraFacing === "user");
    }
    const imgWasCaptured = true;
    if (!firstFaceResult) {
      const fdMinSize = +(face_fd_min_h_roi_frac * roi[3]).toFixed(0);
      handleFirstPhoto({
        blob: blob as Blob,
        url,
        fdMinSize,
        imgWasCaptured,
      });
    } else {
      const fdMinSize = +(doc_fd_min_h_frac * videoSize.h).toFixed(0);
      handleSecondPhoto({
        blob: blob as Blob,
        url,
        fdMinSize,
        imgWasCaptured,
      });
    }
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const fileObj = await handleFileSelection_file(e);
      if (!fileObj) return;
      const { path } = fileObj;
      const { blob, url } = await convertToJpeg(path);
      const { height } = await getImageSize(url);
      docPreviewRef.current!.src = url;
      setShowDocPreview(true);
      const imgWasCaptured = false;
      if (!firstFaceResult) {
        const fdMinSize = +(doc_fd_min_h_frac * height).toFixed(0);
        handleFirstPhoto({
          blob: blob as Blob,
          url,
          fdMinSize,
          imgWasCaptured,
        });
      } else {
        const fdMinSize = +(doc_fd_min_h_frac * height).toFixed(0);
        handleSecondPhoto({
          blob: blob as Blob,
          url,
          fdMinSize,
          imgWasCaptured,
        });
      }
    } catch (e: any) {
      console.log(e);
      notifyError(`errors.${e.message}`);
    }
  };

  const handleFirstPhoto = async ({
    blob,
    url,
    fdMinSize,
    imgWasCaptured,
  }: {
    blob: Blob;
    url: string;
    fdMinSize: number;
    imgWasCaptured: boolean;
  }) => {
    try {
      setRequestLoader(true);
      const { data: res } = await detectFaceRequest(blob, fdMinSize);
      const faces = res.data.sort(
        (f1, f2) =>
          f2.bbox.width * f2.bbox.height - f1.bbox.height * f1.bbox.width
      ); // sort from biggest to smallest bbox
      const facesFound = faces.length;
      if (!facesFound) {
        throw new Error("NoFacesDetected");
      }
      if (imgWasCaptured) {
        if (facesFound > 1) {
          throw new Error("TooMuchFacesDetected");
        }
        if (!bboxIsInsideRoi(faces[0].bbox, roi)) {
          throw new Error("FaceIsOutOfRoi");
        }
      }

      const aliveFaces = faces.filter(
        (f) => (f.liveness || -1) >= liveness_threshold
      );
      if (!aliveFaces.length) {
        throw new Error("NoAliveFacesFound");
      }
      setFirstPhotoHasMultipleFaces(facesFound > 1);
      setFirstFaceResult(aliveFaces[0]);
      setFirstFaceImgUrl(url);
      notifyInfo("messages.uploadDocument");
    } catch (e: any) {
      console.log(e);
      notifyError(`errors.${e.message}`);
    } finally {
      clearCanvas(previewCanvasRef.current!, cameraFacing === "user");
      setRequestLoader(false);
      setShowDocPreview(false);
    }
  };

  const handleSecondPhoto = async ({
    blob,
    url,
    fdMinSize,
    imgWasCaptured,
  }: {
    blob: Blob;
    url: string;
    fdMinSize: number;
    imgWasCaptured: boolean;
  }) => {
    try {
      setRequestLoader(true);
      const { data: res } = await detectDocumentRequest(
        blob as Blob,
        fdMinSize
      );
      const faces = res.data.sort(
        (f1, f2) =>
          f2.bbox.width * f2.bbox.height - f1.bbox.height * f1.bbox.width
      );
      const facesFound = faces.length;
      if (!facesFound) {
        throw new Error("NoFacesDetected");
      }
      if (imgWasCaptured) {
        if (facesFound > 1) {
          throw new Error("TooMuchFacesDetected");
        }
      }
      const notAliveFaces = faces.filter(
        (f) => (f.liveness || liveness_threshold) < liveness_threshold
      );
      if (!notAliveFaces.length) {
        throw new Error("NoDocumentsFacesFound");
      }
      setSecondPhotoHasMultipleFaces(facesFound > 1);
      setSecondFaceResult(notAliveFaces[0]);
      setSecondFaceImgUrl(url);
    } catch (e: any) {
      console.log(e);
      notifyError(`errors.${e.message}`);
      setRequestLoader(false);
      setShowDocPreview(false);
    }
  };

  const comparePhotos = async () => {
    try {
      let blob1 = await fetch(firstFaceImgUrl).then((r) => r.blob());
      let blob2 = await fetch(secondFaceImgUrl).then((r) => r.blob());
      const { bbox: bbox1 } = firstFaceResult!;
      const { bbox: bbox2 } = secondFaceResult!;
      const bbox1Arr = [bbox1.x, bbox1.y, bbox1.width, bbox1.height];
      const bbox2Arr = [bbox2.x, bbox2.y, bbox2.width, bbox2.height];
      const score = await compareRequest(blob1, blob2, bbox1Arr, bbox2Arr);
      const scoreInPercent = (score * 100).toFixed(2);
      setPhotosCompareScore(score);
      setShowResults(true);
      if (score < similarity_threshold) {
        notifyError("errors.failed", { score: scoreInPercent });
      } else {
        notifySuccess("messages.success", { score: scoreInPercent });
      }
    } catch (e: any) {
      console.log(e);
      notifyError("errors.UnknownError");
    } finally {
      setRequestLoader(false);
    }
  };

  const tryToSwitchCamera = async () => {
    const switchTo = cameraFacing === "user" ? "environment" : "user";
    setSwitchCamLoader(true);
    try {
      await switchCam({
        ...constraints,
        video: { facingMode: switchTo, width: 1920, height: 1080 },
      });
      setCameraFacing(switchTo);
    } catch (e: any) {
      console.log(e);
      notifyError(`errors.${e.message}`);
    } finally {
      setTimeout(() => {
        setSwitchCamLoader(false);
      }, 1000);
    }
  };

  const tryToStartCamera = async () => {
    setSwitchCamLoader(true);
    try {
      await startCam(constraints);
    } catch (e: any) {
      notifyError(`errors.${e.message}`);
    } finally {
      setTimeout(() => {
        setSwitchCamLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (secondFaceResult) {
      comparePhotos();
    }
  }, [secondFaceResult]);

  useEffect(() => {
    const roi = calculateRoiPosition(
      videoSize.w,
      videoSize.h,
      face_fd_roi_visualization_shrink_frac
    );
    setRoi(roi);
    if (roiCanvasRef.current && videoSize.w && videoSize.h) {
      drawROI(roiCanvasRef.current, videoSize.w, videoSize.h, roi);
    }
  }, [videoSize]);

  useEffect(() => {
    tryToStartCamera();
    notifyInfo("messages.takeFramedPicture");
  }, []);

  return (
    <div className={className} {...props}>
      <DocImgPreview
        loading={requestLoader}
        ref={docPreviewRef}
        style={{ display: showDocPreview ? "block" : "none" }}
      />
      <UserVideo
        ref={videoRef}
        loading={requestLoader && !showDocPreview}
        mirrored={cameraFacing === "user"}
        addClasses={[videoClass]}
        style={{ display: showDocPreview ? "none" : "block" }}
      >
        {!firstFaceResult && (
          <canvas ref={roiCanvasRef} className={styles["canvas"]}></canvas>
        )}
        <canvas ref={previewCanvasRef} className={styles["canvas"]}></canvas>
      </UserVideo>

      {switchCamLoader ? (
        <div className={styles["switch-cam-loader"]}>
          <Spinner />
        </div>
      ) : (
        <div className={styles["buttons"]}>
          {cameraList.length > 1 && checkMobile() && (
            <Button onClick={tryToSwitchCamera} disabled={requestLoader}>
              <FlipCameraIosIcon />
            </Button>
          )}
          <Button
            onClick={handleCapture}
            disabled={requestLoader}
            variant="contained"
          >
            <CameraIcon />
          </Button>
          <UploadButton
            disabled={requestLoader}
            handleUpload={handleFileUpload}
          />
        </div>
      )}
    </div>
  );
};

export default InitialScreen;
