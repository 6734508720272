export const constraints = {
  audio: false,
  video: {
    facingMode: "user",
    width: 1920,
    height: 1080,
  },
};

// Colors
export const main = "#036AB5";
export const secondary = "#D8E3EC";
export const text = "#263B47";
export const roiColor = "#1565c0";
